.left {
    float: left;
    width: 55%;
}

.right {
    float: right;
    width: 45%;
}

.clear {
    float: none;
    clear: both;
}

.info-title {
    font-weight: 700;
    padding-left: 45px;
    padding-right: 60px;
    padding-bottom: 5px;
    font-size: 22px;
    padding-top: 20px;
}

.line {
    height: 2px;
    background-color: rgb(163, 163, 163);
    width: calc(100% - 105px);
    margin-left: 45px;
}

.info {
    padding-left: 45px;
    padding-right: 60px;
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 25px;
}

.box {
    width: calc(100% - 80px);
    margin-left: 30px;
    background-color: gray;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.54);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.54);
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.54);
    transform: translateY(-65px);
    overflow: auto;
}

.est-title {
    text-align: center;
    padding-top: 50px;
    padding-bottom: 5px;
    font-size: 30px;
    color: white;
    font-weight: 200;
}

.est-body {
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
    color: white;
    font-weight: 200;
}

.contact-button {
    width: 150px;
    height: 50px;
    background-color: #D90429;
    margin: auto;
    margin-top: 20px;
    color: white;
    text-align: center;
    margin-top: 60px;
    margin-bottom: 80px;
    overflow: auto;
    cursor: pointer;
    border-radius: 1px;
}

.contact-button:hover {
    background-color: #cd0b2c;
}

@media (max-width: 745px) {
    .left {
        float: left;
        width: 100%;
    }
    
    .right {
        float: right;
        width: 100%;
    }

    .box {
        transform: translateY(0px);
        margin-bottom: 55px;
        margin-top: 25px;
    }

    .box {
        margin-left: 40px;
    }
}
