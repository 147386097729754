header {
    text-align: center;
    height: 290px;
    background: white;
  }
  
  /* angled div */
  .slanted-div {
    position: relative;
    min-height: 75vh;
    background: url('../assets/background.webp') repeat;
    overflow: visible;
    z-index: 1;
}
  
  /* where the magic happens */
  .slanted-div:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background: inherit;
    z-index: -1;
    top: 0;
    transform-origin: left top;
    transform: skewY(-3deg);
  }

  @media (max-width: 745px) {
    header {
      text-align: center;
      height: 350px;
      background: white;
    }
  }