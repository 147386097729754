/* src/styles/Gallery.css */

.gallery-section {
    transform: translateY(-40px);
}

.gal-title {
    font-size: 40px;
    text-align: center;
    font-weight: 200;
}

.gallery-wrapper {
    width: 80%;
    margin: 0 auto; /* Center the gallery horizontally */
    padding-bottom: 50px;
  }
  
  .image-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    height: 400px; /* Fixed height to center images vertically */
    overflow: hidden; /* Hide overflow to maintain aspect ratio */
  }
  
  .image-container img {
    max-height: 400px; /* Scale images to a max height of 400px */
    max-width: 100%; /* Ensure images do not exceed container width */
    height: auto; /* Maintain aspect ratio */
    width: auto; /* Maintain aspect ratio */
    margin: 0 auto; /* Center horizontally */
    display: block; /* Ensure the image is treated as a block element */
  }
  
  /* Custom styles for the slider arrows */
  .slick-prev:before,
  .slick-next:before {
    color: #020122 !important; /* Change arrow color to black */
  }
  
  @media (max-width: 1110px) {
    .image-container {
      height: 300px; /* Fixed height to center images vertically */
    }
    .image-container img {
      max-height: 300px;
    }
  }

  @media (max-width: 745px) {
    .image-container {
      height: 250px; /* Fixed height to center images vertically */
    }
    .image-container img {
      max-height: 250px;
    }
  }

  @media (max-width: 425px) {
    .image-container {
      height: 150px; /* Fixed height to center images vertically */
    }
    .image-container img {
      max-height: 150px;
    }
  }

