.about {
    background-color: #61707D;
    width: 100%;
    display: flex;
    align-items: center;
}

.left-about {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.left-about img {
    width: calc(100% - 80px);
    margin-left: 40px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.right-about {
    width: 50%;
}

.about-text {
    color: white;
    font-size: 20px;
    padding-left: 30px;
    padding-right: 30px;
    font-weight: 100;
    padding-bottom: 20px;
}

.about-title {
    font-weight: 600;
    font-size: 40px;
    padding-left: 30px;
    color: white;
    margin-bottom: 25px;
    margin-top: 10px;
    padding-top:20px;
}

.clear {
    clear: both;
    float: none;
}

@media (max-width: 745px) {
    .about {
        flex-direction: column; /* stack items vertically */
    }
    .left-about {
        width: 100%; /* full width for image container */
    }
    .left-about img {
        width: calc(100% - 60px); /* adjust width */
        margin-left: 30px;
        margin-right: 30px;
    }
    .right-about {
        width: 100%; /* full width for text container */
    }
    .about-text, .about-title {
        padding-left: 15px; /* reduce padding */
        padding-right: 15px;
        text-align: center;
    }

    .about-text {
        padding-bottom: 30px;
    }
}