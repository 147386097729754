.header-img {
    height: 486.3px;
    width: 100%;
    object-fit: cover; /* This ensures the image covers the container */
    object-position: center; /* This centers the image */
}

.header {
    position: relative;
    width: 100%;
    height: auto;
}

.centered-text {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    font-size: 50px;
    font-weight: bold;
    text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.7);
}

@media (max-width: 745px) {
    .centered-text {
        width: 80%;
    }
}