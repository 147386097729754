.container {
    margin: auto;
    max-width: 1100px;
    transform: translateY(-306px);
    background-color: #EDF2F4;
}

@media (max-width: 1120px) {
    .container {
      max-width: calc(100% - 20px);
    }
  }

  @media (max-width: 745px) {
    .container {
      transform: translateY(-366px);
    }
  }