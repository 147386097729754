.nav-bar {
    width: 100%;
    height: 180px;
    background-color: white;
}

.logo {
    height: 130px;
    padding-top: 31px;
    margin-left: 30px;
    float:left;
    position: relative;
}

.nav-links {
    float: right;
    margin-right: 30px;
    width: 350px;
}

.link {
    float: right;
    padding-left: 10px;
    padding-right: 40px;
    font-size: 20px;
    cursor: pointer;
    margin-top: 65px;
}

.link:hover {
    text-decoration: underline;
}

.link-box {
    border: 1px solid rgb(85, 24, 24);
    border-radius: 10px;
    background-color: #EF233C;
    padding-left: 20px;
    padding-right: 20px;
    color: white;
    float: right;
    font-size: 20px;
    cursor: pointer;
    margin: 0px;
    margin-top: 65px;
    -webkit-box-shadow: 5px 5px 13px 0px rgba(0,0,0,0.22);
    -moz-box-shadow: 5px 5px 13px 0px rgba(0,0,0,0.22);
    box-shadow: 5px 5px 13px 0px rgba(0,0,0,0.22);
}

.link-box:hover {
    -webkit-box-shadow: 5px 5px 13px 0px rgba(0,0,0,0);
    -moz-box-shadow: 5px 5px 13px 0px rgba(0,0,0,0);
    box-shadow: 5px 5px 13px 0px rgba(0,0,0,0);
}

.clear {
    clear: both;
    float: none;
}

@media (max-width: 745px) {
    .nav-bar {
        height: 300px;
    }

    .link {
        margin-top:25px;
    }

    .link-box {
        margin-top:25px;
    }

    .logo {
        float: none;
        margin: auto;
        margin-left: calc(50% - 160px)
    }

    .nav-links {
        float: none;
        clear: both;
        margin: auto;
        transform: translateX(-10px);
    }
}