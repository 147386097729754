.contact {
    overflow: auto;
}

.contact-title {
    font-size: 40px;
    text-align: center;
    font-weight: 200;
    padding-bottom: 10px;
}

.contact-card {
    max-width: 400px;
    padding-bottom: 50px;
    margin: auto;
}

.contact-card img {
    width: 35%;
    margin-left: 5%;
    float: left;
}

.right-card {
    width: 55%;
    float: right;
}

.right-card p {
    font-size: 20px;
    padding-left: 20px;
}

.contact-name {
    font-weight: 700;
    padding-top: 10px;
}

.clear {
    clear: both;
    float: none;
}